import {environment} from '../../../../environments/environment';

export interface FooterLinkItem {
  link: string;
  label: string;
}

export interface FooterItem {
  label: string;
  items: FooterLinkItem[];
}

export const PersonalItem: FooterItem = {
  label: 'บุคคลธรรมดา',
  items: [
    {
      link: '/app/',
      label: 'คำนวณภาษี / วางแผนภาษี'
    },
    {
      link: '/bnk/',
      label: 'บัญชีธนาคารเพื่อ e-commerce'
    }
  ]
};

export const CoperateItem: FooterItem = {
  label: 'บริษัท / ห้างหุ้นส่วน',
  items: [
    {
      link: '/sme/',
      label: 'จดทะเบียนบริษัท'
    },
    {
      link: `/paystation/`,
      label: 'โปรแกรมเงินเดือน'
    },
    {
      link: '/sme/',
      label: 'บัญชี'
    },
    {
      link: '/vat/',
      label: 'คำนวณภาษีหัก ณ ที่จ่าย'
    }
  ]
};

export const TaxItem: FooterItem = {
  label: 'ลดหย่อนภาษี',
  items: [
    {
      link: `${ environment.shopBaseUrl }`,
      label: 'ประกันชีวิต'
    },
    {
      link: `${ environment.shopBaseUrl }/saving`,
      label: 'ประกันออมทรัพย์'
    },
    {
      link: `${ environment.shopBaseUrl }/term`,
      label: 'ประกันชีวิตชั่วระยะเวลา'
    },
    {
      link: `${ environment.shopBaseUrl }/wholelife`,
      label: 'ประกันชีวิตตลอดชีพ'
    },
    {
      link: `${ environment.shopBaseUrl }/annuity`,
      label: 'ประกันชีวิตบำนาญ'
    },
    {
      link: `${ environment.shopBaseUrl }/health`,
      label: 'ประกันสุขภาพ'
    },
    {
      link: `${ environment.shopBaseUrl }/ci`,
      label: 'ประกันโรคร้ายแรง'
    },
    {
      link: `${ environment.shopBaseUrl }/fund`,
      label: 'กองทุน RMF / Thai ESG'
    },
    // {
    //   link: `${ environment.shopBaseUrl }/homeloan`,
    //   label: 'ดอกเบี้ยบ้าน'
    // },
    // {
    //   link: `/event`,
    //   label: `iTAX ${new Date().getFullYear()} เทศกาลลดหย่อนภาษีประจำปี`
    // },
    // {
    //   link: `${ environment.shopBaseUrl }/shopping`,
    //   label: 'ช้อปดีมีคืน'
    // }
  ]
};

export const ArticleItem: FooterItem = {
  label: 'บทความ',
  items: [
    {
      link: `${ environment.mainBaseUrl }/pedia/`,
      label: 'ความรู้ภาษี'
    },
    {
      link: `${ environment.mainBaseUrl }/media/`,
      label: 'ข่าวภาษี'
    }
  ]
};

export const ITaxItem: FooterItem = {
  label: 'iTAX',
  items: [
    {
      link: `${ environment.mainBaseUrl }/pedia/iTAX/`,
      label: 'ทำไมต้อง iTAX'
    },
    {
      link: `${ environment.mainBaseUrl }/pedia/iTAX/`,
      label: 'เกี่ยวกับ iTAX'
    },
    {
      link: `${ environment.mainBaseUrl }/pedia/นโยบายความเป็นส่วนตัว/`,
      label: 'นโยบายความเป็นส่วนตัว'
    },
    {
      link: `/jobs/`,
      label: 'สมัครงาน'
    }
  ]
};
